<template>
    <KCourse loader-class="MBcont" course-id="8" title="Будуарная кукла" :items="items">
        <div class="MBtextcont">
            <h3>1. Расписываем глаза</h3>
        </div>
                
        <VideoView video-id="1c68488da0394e30a64a0572c9c17a62"/>

        <div class="MBtextcont">
            <h3>1.2. Более подробная роспись глаза на бумаге</h3>
        </div>

        <VideoView video-id="b3e1143c2cb14caaab08c470a93f29e3"/>

        <div class="MBtextcont">
            <h3>2. Дорасписываем глаза + брови</h3>
        </div>

        <VideoView video-id="20110197a7364a4ba38b276e4855816e"/>

        <div class="MBtextcont">
            <h3>3. Выделяем основные объемы на лице</h3>
        </div>
                
        <VideoView video-id="c1c81312ca9d4bdcbbed16bbc80399f0"/>

        <div class="MBtextcont">
            <h3>4. Расписываем тело</h3>
        </div>          

        <VideoView video-id="d1118d5b4e364a44841293ae9e034d1d"/>


        <div class="MBtextcont">
            <h3 class="nomargin">Итого, что нужно сделать:</h3>
            <ol>
                <li>Расписать голову и тело</li>
                <li>Зафиксировать все лаком</li>
                <li><b>Подготовить материалы</b> к следующему занятию:</li>
                <ol type="a">
                    <li>Клей Момент Кристалл</li>
                    <li>Бритва (можно специальную филлировочную)</li>
                </ol>
            </ol>

           <h3>Время выполнения: 4 часа</h3>
        </div>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";
    import chapters from './data.js';
    import KImage from "../../Widgets/KImage";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
            KImage
        },
        data() {
        return {
            items: chapters
        }
        },
        methods: {
            showImage(src, customClass) {
                this.$swal.fire({
                    customClass: customClass,
                    background: '#ffffff',
                    html: '<img width="100%" src="'+src+'"/>',
                    showCloseButton: true,
                    showConfirmButton: false,
                    // closeButtonHtml: "",
                })
            },
        }
    }
</script>

<style>

</style>